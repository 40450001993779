<template>
  <div class="vx-row">
    <div class="vx-card p-6">
      
        <h5 style="margin-bottom: 9px"> {{ $acl.check('admin') ? 'Administradores' : 'Personal de Cafetería' }}</h5>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{currentPage * paginationPageSize - (paginationPageSize - 1)}} - {{staffData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize: staffData.length}} de {{ staffData.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Buscar..."
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
          </div>
        </div>
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="staffData"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          v-show="staffData.length > 0"
          :modules="modules"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      
    </div>
  </div>
</template>

<script>
// Store Module
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import reportModule from "@/store/report/moduleReport.js";
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  data() {
    return {
      modules: AllCommunityModules,

      themeColors: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
      donutChart: {
        series: [],
        chartOptions: {
          colors: this.themeColors,
          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
      //Table
      searchQuery: "",
      gridOptions: {},

      maxPageNumbers: 5,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Nombre",
          field: "name",
          width: 250,
          filter: true,
          floatingFilter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Apellido",
          field: "last_name",
          width: 250,
          filter: true,
          floatingFilter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Email",
          field: "email",
          width: 250,
          filter: true,
          floatingFilter: true,
          cellStyle: {'text-transform': 'lowercase'}
        },
        {
          headerName: "Tipo Doc.",
          field: "description",
            width: 160,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: "Documento",
          field: "card",
          width: 160,
          filter: true,
          floatingFilter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Perfil",
          field: "profile_type_description",
          width: 180,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: "Dirección",
          field: "address",
          width: 160,
          filter: true,
          floatingFilter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Ciudad",
          field: "city",
          width: 160,
          filter: true,
          floatingFilter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Zip Code",
          field: "zip_code",
          width: 160,
          filter: true,
          floatingFilter: true,
          cellClass: "cellMayusc",
        },
        {
          headerName: "Telefono",
          field: "phone_number",
          width: 160,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: "Fecha de Nacimiento",
          field: "birthday_date",
          width: 180,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: "Edad",
          field: "age",
          width: 140,
          filter: true,
          floatingFilter: true,
        },
        {
          headerName: "Empresa",
          field: "company_name",
          width: 200,
          filter: true,
          floatingFilter: true,
          hide: !this.$acl.check('admin')
        },
        {
          headerName: "Nro. de Identificación",
          field: "company_code",
          width: 160,
          filter: true,
          floatingFilter: true,
          hide: !this.$acl.check('admin')
        },
      ],
      staffData: [],
    };
  },
  components: {
    AgGridVue,
  },
  created() {
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }

    if (!moduleUserManagement.isRegistered) {
      this.$store.registerModule("userManagement", moduleUserManagement);
      moduleUserManagement.isRegistered = true;
    }

    if(this.$acl.check('admin'))
      this.loadOwnersDataTable();

    if(this.$acl.check('manager'))
      this.loadstaffDataTable();
  },
  methods: {
    getAge(birthday_date) {
      return Math.floor((new Date() - new Date(birthday_date).getTime()) / 3.15576e10);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    loadOwnersDataTable() {
      this.$store.dispatch(`userManagement/getOwners`)
          .then(() => {
            let owners = JSON.parse(JSON.stringify(this.$store.state.userManagement.owners));
            if(owners && owners.length) {
              owners.forEach(owner => {
                owner.profile_type_description = this.$store.state.AppData.profileTypes.find((t) => t.profile_type == owner.profile_type).profile_type_description;
                 owner.description = this.$store.state.AppData.cardTypes.find((t) => t.card_type_id == owner.card_type_id).description;
                owner.age = this.getAge(new Date(owner.birthday_date));
                owner.card = owner.card_id;
                owner.birthday_date = new Date(owner.birthday_date).toLocaleDateString("es-AR", this.$localeDateOptions);
              })
              this.staffData = owners;
            }
          })
          .catch(error => console.log(error))
    },
    loadstaffDataTable() {
      this.$store.dispatch("reportModule/getStaffData",this.$store.state.AppActiveUser.institution.id)
        .then((response) => {
          if (response.data.length > 0) {
            let s = response.data;

            s.forEach((element) => {
              element.name = element.profile.name
              element.last_name = element.profile.last_name
              element.birthday_date = new Date(element.profile.birthday_date).toLocaleDateString("es-AR", this.$localeDateOptions);
              element.age = this.getAge(new Date(element.profile.birthday_date));
              element.card = element.profile.card_id;
              element.profile_type_description = this.$store.state.AppData.profileTypes.find((t) => t.profile_type == element.profile.profile_type).profile_type_description;
              element.description = this.$store.state.AppData.cardTypes.find((t) => t.card_type_id == element.profile.card_type_id).description;
              element.address = element.profile.address
              element.city = element.profile.city
              element.zip_code = element.profile.zip_code
              element.phone_number = element.profile.phone_number
              element.email = element.account.email
            });
            this.staffData = JSON.parse(JSON.stringify(s));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /*  updateUsersChart() {
        this.$vs.loading({
            container: "",
            scale: 0.45
        })

        this.$store.dispatch("userManagement/getStaff", this.$store.state.AppActiveUser.institution.id)
        .then(response => {
            if (response.data.status) {
                this.getSeries(response.data.data)
            }
            this.$vs.loading.close(".con-vs-loading")
        })
        .catch(error => {
            this.$vs.loading.close(".con-vs-loading")
            console.log(error)
        })

      }, */
    /*   getSeries(users) {

          if (!users) return
                    
          let series = []
          let staff_labels = []


          this.$store.state.AppData.profileTypes.forEach(element => {
              staff_labels.push(element.profile_type_description)
              series.push(users.filter(function(e){
                  return e.profile.profile_type == element.profile_type
              }).length)
          })

          this.donutChart.series = series
          this.$refs.usersChart.updateOptions({ labels: staff_labels })
      } */
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  
};
</script>
<style lang="stylus"></style>